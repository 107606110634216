// ** React Imports
import { ReactNode, useRef } from 'react'

// ** Next Imports

// ** MUI Imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Paper from '@mui/material/Paper'

// ** Custom Components Imports
import CustomAlurCard from 'src/components/home/CustomAlurCard'
import CustomTataCaraMelamar from 'src/components/home/CustomTataCaraMelamar'
import CustomKetentuanUmum from 'src/components/home/CustomKetentuanUmum'
import CustomDocument from 'src/components/home/CustomDocument'
import CustomLihatRekrutmen from 'src/components/shared/CustomLihatRekrutmen'
import CustomMapLocal from 'src/components/shared/CustomMapLocal'

// ** Icons Imports
import FolderSharedRoundedIcon from '@mui/icons-material/FolderSharedRounded'
import QuizRoundedIcon from '@mui/icons-material/QuizRounded'
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded'
import MedicalInformationRoundedIcon from '@mui/icons-material/MedicalInformationRounded'
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded'

// ** Layout Import
import BlankLayoutAppBar from 'src/layouts/BlankLayoutWithAppBar'

const Home = () => {
  const elementRef = useRef<HTMLDivElement>(null)

  const scrollToTataCara = () => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container sx={{ p: 6 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 5,
              justifyContent: 'center'
            }}
          >
            <Typography variant='h3' fontWeight={600} color={theme => theme.palette.primary.main}>
              Bankaltimtara Terus Ciptakan Lapangan Kerja
            </Typography>
            <Typography variant='body2'>
              Lebih dari 400 posisi jabatan di lebih dari 200 Jaringan kantor Bankaltimtara, menunggu Putra Putri
              Terbaik Bangsa melalui Rekrutmen Bankaltimtara. Mari berikan kontribusi yang terbaik dalam mendukung
              pertumbuhan ekonomi daerah yang berkualitas dan berkelanjutan.
            </Typography>
            <Typography variant='subtitle2' fontWeight={600}>
              Dari Kalimantan, Membangun Indonesia
            </Typography>
            <Box>
              <Button
                variant='contained'
                sx={{ borderRadius: 5, textTransform: 'capitalize' }}
                onClick={scrollToTataCara}
              >
                Tata Cara Melamar
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <img src={`${process.env.NEXT_PUBLIC_BASE_PATH}/images/hiring.svg`} alt='Hiring Isometric Illustrations' />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ borderRadius: 1, mb: 10 }}>
        <Paper elevation={5}>
          <Grid container sx={{ justifyItems: 'center', p: 10, pb: 20 }}>
            <Grid item xs={12} sx={{ mb: 6 }}>
              <Typography
                variant='h4'
                fontWeight={600}
                textAlign={'center'}
                color={theme => theme.palette.primary.main}
                letterSpacing={0}
              >
                Raih Impianmu Bersama Bankaltimtara
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: 2 }}>
              <Card
                sx={{
                  backgroundColor: theme => theme.palette.primary.main,

                  // backgroundImage: `url(${process.env.NEXT_PUBLIC_BASE_PATH}/images/cardbg.svg)`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  height: '100%'
                }}
              >
                <CardContent sx={{ color: '#e4e6eb' }}>
                  <Typography variant='h5' fontWeight={600} sx={{ color: '#e4e6eb' }}>
                    200+
                  </Typography>
                  <Typography variant='body2' fontWeight={500} sx={{ mb: 6, color: '#e4e6eb' }}>
                    Jaringan Kantor
                  </Typography>
                  <Typography variant='body1' fontWeight={500} sx={{ mb: 10, color: '#e4e6eb' }}>
                    Bankaltimtara telah melakukan ekspansi lebih dari 200 jaringan kantor di Wilayah Operasinal
                    Kalimantan Timur, Kalimantan Utara dan DKI Jakarta.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: 2 }}>
              <Card
                sx={{
                  backgroundColor: theme => theme.palette.secondary.main,

                  // backgroundImage: `url(${process.env.NEXT_PUBLIC_BASE_PATH}/images/cardbg.svg)`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  height: '100%'
                }}
              >
                <CardContent>
                  <Typography variant='h5' fontWeight={600} sx={{ color: theme => theme.palette.primary.main }}>
                    400+
                  </Typography>
                  <Typography
                    variant='body2'
                    fontWeight={500}
                    sx={{ mb: 6, color: theme => theme.palette.primary.main }}
                  >
                    Posisi Jabatan
                  </Typography>
                  <Typography
                    variant='body1'
                    fontWeight={500}
                    sx={{ mb: 10, color: theme => theme.palette.primary.main }}
                  >
                    Bankaltimtara telah mengembangkan lebih dari 400 posisi jabatan dengan kebutuhan kompetensi,
                    tingkatan level karir dan klasifikasi jabatan yang berbeda-beda.{' '}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <Grid container sx={{ p: 6 }}>
          <Grid item xs={12} md={6} lg={4} sx={{ height: '100%', p: 10 }}>
            <Typography variant='h4' color='primary' fontWeight={600} letterSpacing={0} sx={{ mb: 6 }}>
              Alur Rekrutmen
            </Typography>
            <Typography variant='body2' lineHeight={2}>
              Terdapat 5 Alur tahapan utama yang harus diikuti pelamar pada Rekrutmen Bankaltimtara baik untuk Pegawai
              Tetap maupun Pegawai Kontrak.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ p: 2 }}>
            <CustomAlurCard
              title={`01 Tahap
                    Registrasi Online & Seleksi Berkas`}
              caption={`Pelamar akan melakukan registrasi akun dan mengisi curriculum vitae.
                    Selanjutnya pelamar dapat memilih dan mendaftar pada lowongan yang tersedia.`}
              icon={<FolderSharedRoundedIcon color='secondary' sx={{ fontSize: '3rem' }} />}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ p: 2 }}>
            <CustomAlurCard
              title={`02 Tahap
                    Tes Potensi Akademik`}
              caption={`Pelamar akan melakukan tes akademik sederahana meliputi tes verbal, angka, logika, spasial/gambar, wawasan umum, dan perbankan.`}
              icon={<QuizRoundedIcon color='secondary' sx={{ fontSize: '3rem' }} />}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ p: 2 }}>
            <CustomAlurCard
              title={`03 Tahap
                Psikotes & Leaderless Discussion Group`}
              caption={`Pelamar akan melakukan Tes Psikologi, Sesi Wawancara Asessor, dan Sesi LGD (Leaderless Discussion Group)`}
              icon={<PsychologyRoundedIcon color='secondary' sx={{ fontSize: '3rem' }} />}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ p: 2 }}>
            <CustomAlurCard
              title={`04 Tahap
                Tes Kesehatan /  Medical Check Up`}
              caption={`Pelamar akan melakukan beberapa tes pemeriksaan klinis dan pencatatan rekam medis secara menyeluruh.`}
              icon={<MedicalInformationRoundedIcon color='secondary' sx={{ fontSize: '3rem' }} />}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ p: 2 }}>
            <CustomAlurCard
              title={`05 Tahap
                Wawancara Akhir Rekrutmen`}
              caption={`Pelamar akan melakukan sesi wawancara akhir bersama Pejabat-Pejabat Eksekutif Bankaltimtara.`}
              icon={<QuestionAnswerRoundedIcon color='secondary' sx={{ fontSize: '3rem' }} />}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <Grid container sx={{ p: 6, alignItems: 'start' }}>
          <Grid item xs={12} md={6} sx={{ px: 5 }}>
            <Typography variant='h4' color='primary' fontWeight={600} letterSpacing={0} sx={{ mb: 6 }}>
              Ketentuan Umum
            </Typography>
            <Typography variant='body2' lineHeight={2}>
              Terdapat 7 ketentuan umum yang harus diperhatikan oleh calon pelamar untuk mendaftar pada rekrutmen
              bankaltimtara.
            </Typography>
            <img
              src={`${process.env.NEXT_PUBLIC_BASE_PATH}/images/completed.svg`}
              alt='Hiring Isometric Illustrations'
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ px: 5 }}>
            <CustomKetentuanUmum
              nomor='01'
              text='Terdapat 7 ketentuan umum yang harus diperhatikan oleh calon pelamar untuk mendaftar pada rekrutmen bankaltimtara.'
            />
            <CustomKetentuanUmum
              nomor='02'
              text='Seluruh kegiatan seleksi online dan pengumuman tiap tahapan seleksi diumumkan melalui https://career.bankaltimtara.co.id'
            />
            <CustomKetentuanUmum
              nomor='03'
              text='Seluruh tahapan rekrutmen tidak dipungut biaya apapun. Apabila ada pihak yang meminta biaya/ menjanjikan sesuatu/ menawarkan bantuan atas proses rekrutmen dapat melapor ke Divisi Human Capital.'
            />
            <CustomKetentuanUmum
              nomor='04'
              text='Seluruh biaya akomodasi dan transportasi selama proses rekrutmen dan seleksi menjadi tanggungan pelamar.'
            />
            <CustomKetentuanUmum
              nomor='05'
              text='Seluruh Pelamar diwajibkan mengajukan lamaran dengan melakukan registrasi online melalui https://career.bankaltimtara.co.id'
            />
            <CustomKetentuanUmum
              nomor='06'
              text='Divisi Human Capital berhak sepenuhnya menetapkan daftar kandidat yang dinilai memenuhi kualifikasi pada setiap tahapan seleksi.'
            />
            <CustomKetentuanUmum
              nomor='07'
              text='Hasil keputusan Divisi Human Capital bersifat final, mengikat, dan tidak dapat diganggu gugat.'
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 10 }} ref={elementRef}>
        <Typography variant='h4' color='primary' fontWeight={600} textAlign='center' letterSpacing={0} sx={{ mb: 6 }}>
          Tata Cara Melamar
        </Typography>
        <Grid container sx={{ p: 4 }}>
          <Grid item xs={12} sx={{ pb: 4 }}>
            <CustomTataCaraMelamar
              nomor={`01`}
              text={`Pelamar hanya dapat melamar melalui situs web https://career.bankaltimtara.co.id dengan mengikuti petunjuk pendaftaran. Tidak ada jalur lain yang digunakan untuk pengiriman lamaran.`}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 4 }}>
            <CustomTataCaraMelamar
              nomor={`02`}
              text={`Pelamar wajib memiliki alamat e-mail pribadi dan nomor telepon seluler yang masih aktif untuk dapat mengikuti proses seleksi. Pelamar dilarang menggunakan alamat e-mail milik orang lain/kantor dalam proses pendaftaran.`}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 4 }}>
            <CustomTataCaraMelamar
              nomor={`03`}
              text={`Tidak ada layanan untuk perubahan/koreksi seluruh data-data serta dokumen yang telah dikirim oleh pelamar.`}
            />
          </Grid>
          <Grid item xs={12} sx={{ pb: 4 }}>
            <Card
              sx={{
                '&:hover': {
                  outline: theme => `3px solid ${theme.palette.primary.main}`
                }
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={2} md={1} sx={{ alignSelf: 'center', justifySelf: 'center' }}>
                    <Typography
                      variant='h3'
                      color='secondary'
                      fontWeight={600}
                      letterSpacing={0}
                      sx={{ mr: 6, width: 40, flexShrink: 0 }}
                    >
                      04
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10} md={11} sx={{ alignSelf: 'center' }}>
                    <Typography variant='body1' lineHeight={2}>
                      Untuk memudahkan registrasi online, persiapkan terlebih dahulu beberapa dokumen ini.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Box
                sx={{
                  borderTop: theme => `2px solid ${theme.palette.secondary.main}`,
                  borderBottom: theme => `2px solid ${theme.palette.secondary.main}`
                }}
              >
                <CardContent>
                  <Grid container>
                    <CustomDocument text={'Foto Profil'} status={'Wajib'} />
                    <CustomDocument text={'KTP (Kartu Tanda Penduduk)'} status={'Wajib'} />
                    <CustomDocument text={'Ijazah/Min. Surat Keterangan Lulus'} status={'Wajib'} />
                    <CustomDocument text={'Transkip Nilai'} status={'Wajib'} />
                    <CustomDocument text={'SKCK (Surat Keterangan Catatan Kepolisian)'} status={'Wajib'} />
                    <CustomDocument text={'Surat Keterangan Bebas Narkoba'} status={'Wajib'} />
                    <CustomDocument text={'Sertifikat Toefl'} status={'Wajib'} />
                    <CustomDocument
                      text={'Dokumen Lainnya (Sertifikat Pelatihan, Surat Rekomendasi, dll.)'}
                      status={'Jika Ada'}
                    />
                  </Grid>
                </CardContent>
              </Box>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={2} md={1} sx={{ alignSelf: 'center', justifySelf: 'center' }}>
                    <Typography
                      variant='h3'
                      color='secondary'
                      fontWeight={600}
                      letterSpacing={0}
                      sx={{ mr: 6, width: 40, flexShrink: 0 }}
                    ></Typography>
                  </Grid>
                  <Grid item xs={12} sm={10} md={11} sx={{ alignSelf: 'center' }}>
                    <Typography variant='body1' lineHeight={2}>
                      Setiap dokumen yang terdiri lebih dari 1 (seperti ijazah untuk 2 pendidikan), wajib untuk
                      disatukan dalam 1 file dokumen.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 10, p: 4 }}>
        <CustomLihatRekrutmen />
      </Grid>
      <Grid item xs={12} sx={{ mb: 10 }}>
        <CustomMapLocal />
      </Grid>
    </Grid>
  )
}

Home.getLayout = (page: ReactNode) => <BlankLayoutAppBar>{page}</BlankLayoutAppBar>

Home.authGuard = false

export default Home
