// ** React Imports

// ** MUI Imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// ** Next Imports
import NextLink from 'next/link'

// ** Icons Imports
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded'

const CustomLihatRekrutmen = () => {
  // ** Hooks
  const theme = useTheme()
  const hiddenMd = useMediaQuery(theme.breakpoints.down('md'))
  const hiddenSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container>
      {!hiddenSm ? (
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            backgroundColor: theme => theme.palette.secondary.dark
          }}
        >
          <Typography
            variant={!hiddenMd ? 'h3' : 'h4'}
            fontWeight={600}
            color={theme => theme.palette.primary.main}
            sx={{
              p: !hiddenMd ? 16 : 8,
              py: 16
            }}
          >
            Lihat Rekrutmen yang Tersedia Sekarang
          </Typography>
        </Grid>
      ) : null}
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          borderRadius: hiddenSm ? 10 : 0,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          backgroundColor: theme => theme.palette.primary.main,
          '&:hover': {
            transform: 'scale(1.005)',
            svg: {
              transform: 'scale(1.1) rotate(270deg)'
            }
          }
        }}
      >
        <NextLink href={`/rekrutmen`}>
          <Button variant='contained' sx={{ height: '100%', width: '100%', py: 16 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography
                variant={!hiddenMd ? 'h3' : 'h4'}
                textAlign='center'
                fontWeight={600}
                color={theme => theme.palette.common.white}
                sx={{}}
              >
                Rekrutmen Bankaltimtara
              </Typography>
              <ExpandCircleDownRoundedIcon sx={{ fontSize: '3rem', color: 'white', transform: 'rotate(270deg)' }} />
            </Box>
          </Button>
        </NextLink>
      </Grid>
    </Grid>
  )
}
export default CustomLihatRekrutmen
