// ** React Imports

// ** Next Imports

// ** MUI Imports
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'

// ** Custom Components Imports
import CustomMapPopup from './CustomMapPopup'

export const CustomMapLocal = () => {
  const { settings } = useSettings()

  return (
    <Paper sx={{ overflow: 'hidden' }}>
      <Box
        sx={{
          height: 600,
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: `url("${process.env.NEXT_PUBLIC_BASE_PATH}/images/maps/map-${
            settings.mode === 'dark' ? 'dark' : 'light'
          }.webp"),url("${process.env.NEXT_PUBLIC_BASE_PATH}/images/maps/map-${
            settings.mode === 'dark' ? 'dark' : 'light'
          }.jpg")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 100
          }}
        >
          <CustomMapPopup />
          <PlaceRoundedIcon
            color={`${settings.mode === 'dark' ? 'secondary' : 'primary'}`}
            sx={{ position: 'relative', top: -10, fontSize: '2.5rem' }}
          />
        </Box>
      </Box>
    </Paper>
  )
}

export default CustomMapLocal
