// ** MUI Imports
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

// ** Custom Components Imports
import CustomAvatar from 'src/@core/components/mui/avatar'

interface Props {
  title: string
  caption: string
  icon: JSX.Element
}

const CustomAlurCard = (props: Props) => {
  const { title, caption, icon } = props

  return (
    <Card
      sx={{
        height: '100%',
        '&:hover': {
          outline: theme => `3px solid ${theme.palette.primary.main}`,
        }
      }}>
      <CardContent sx={{ p: 10, mb: 8 }}>
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'space-between', mb: 6 }}>
          <Typography variant='body1' fontWeight={600} color='primary' whiteSpace={'pre-line'} >
            {title}
          </Typography>
          <CustomAvatar skin='light' color='secondary' sx={{ width: 80, height: 80, ml: 2 }}>
            {icon}
          </CustomAvatar>
        </Box>
        <Typography variant='body2' whiteSpace={'pre-line'} lineHeight={2}>
          {caption}
        </Typography>
      </CardContent>
    </Card>
  )
}
export default CustomAlurCard
