// ** MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

export const CustomMapPopup = () => {
  return (
    <Card sx={{ mb: 4 }}>
      <CardContent
        sx={{ display: 'grid', backgroundColor: theme => theme.palette.background.default, justifyItems: 'center' }}
      >
        <Typography variant='body1' textAlign='center' fontWeight={700} color={theme => theme.myColor?.colorToggle}>
          Kantor Pusat
        </Typography>
        <Typography variant='body2' textAlign='center' fontWeight={600}>
          Gedung PT. BPD Kaltim Kaltara Lantai 3 s.d 6
        </Typography>
        <Typography variant='body2' textAlign='center' sx={{ mb: 2 }}>
          Jl. Jend. Sudirman No. 33, Samarinda 75122
        </Typography>
        <Typography variant='caption' textAlign='center'>
          <b>Telp</b> : 0541-7355500, 739569-67
        </Typography>
        <Typography variant='caption' textAlign='center' sx={{ mb: 4 }}>
          <b>Fax</b> : 0541-735580, 748632, 7395711
        </Typography>
      </CardContent>
    </Card>
  )
}

export default CustomMapPopup
