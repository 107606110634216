// ** MUI Imports
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

interface Props {
  nomor: string
  text: string
}

const CustomKetentuanUmum = (props: Props) => {
  const { nomor, text } = props

  return (
    <Grid container sx={{ mb: 8 }} >
      <Grid item xs={12} sm={1} md={2} lg={1} sx={{ alignSelf: 'start', justifySelf: 'center' }}>
        <Typography variant='h4' color='primary' fontWeight={600} letterSpacing={0} sx={{ mr: 6, width: 40, flexShrink: 0 }}>
          {nomor}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={11} md={10} lg={11} sx={{ alignSelf: 'start' }}>
        <Typography variant='body2' lineHeight={2}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default CustomKetentuanUmum
