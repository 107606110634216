import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Box from '@mui/material/Box'

interface Props {
  text: string
  status: string
}
const CustomDocument = (props: Props) => {
  const { text, status } = props

  return (
    <>
      <Grid item xs={12} sm={2} md={1} sx={{ alignSelf: 'top', justifySelf: 'center' }}>
      </Grid>
      <Grid item xs={10} sm={8} md={9} sx={{ alignSelf: 'top', pr: 4, mb: 2, }}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant='body1' lineHeight={2} sx={{ mr: 2 }}>
            -
          </Typography><Typography variant='body1' lineHeight={2}>
            {text}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2} sm={2} md={2} sx={{ alignSelf: 'top' }}>
        <Typography variant='body1' lineHeight={2} color='primary'>
          {status}
        </Typography>
      </Grid>
    </>
  )
}

export default CustomDocument
