// ** MUI Imports
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

interface Props {
  nomor: string
  text: string
}

const CustomTataCaraMelamar = (props: Props) => {
  const { nomor, text } = props

  return (
    <Card
      sx={{
        '&:hover': {
          outline: theme => `3px solid ${theme.palette.primary.main}`,
        }
      }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={2} md={1} sx={{ alignSelf: 'center', justifySelf: 'center', mb: 4 }}>
            <Typography variant='h3' color='secondary' fontWeight={600} letterSpacing={0} sx={{ mr: 6, width: 40, flexShrink: 0 }}>
              {nomor}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={11} sx={{ alignSelf: 'center' }}>
            <Typography variant='body1' lineHeight={2}>
              {text}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
export default CustomTataCaraMelamar
